<template>
  <v-overlay :z-index="100" :value="modalState" opacity="0.9">
    <v-card :loading="loading">
      <v-card-title>
        {{ overlayTitle }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <slot />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="teal" @click="submit()">
          <span v-if="formType == 'edit'">Редактировать</span>
          <span v-else-if="formType == 'add'">Добавить</span>
        </v-btn>
        <v-spacer />
        <v-btn color="teal" @click="$emit('close-modal')"> Закрыть </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
export default {
  name: "OverlayForm",
  props: {
    overlayTitle: {
      type: String,
      default: "Не указано",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // either 'edit' eidther 'add'
    formType: {
      type: String,
      default: "edit",
    },
    modalState: {
      type: Boolean,
      default: false,
      requeired: true,
    },
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("submit-button");
    },
  },
};
</script>

<style lang="scss"></style>
