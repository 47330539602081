<template>
  <div>
    <loader v-if="loading"></loader>

    <!-- ADD STACKTYPE -->
    <overlay-form
      :overlay-title="'Добавить тип оборудования'"
      :form-type="'add'"
      :modal-state="addTypeModal"
      @close-modal="addTypeModal = false"
      @submit-button="addNewStackType()"
    >
      <v-form>
        <v-text-field v-model.trim="input.name" label="Полное название" />
        <v-text-field
          v-model.trim="input.codeName"
          label="Кодовое наименование"
        />
      </v-form>
    </overlay-form>

    <!-- EDIT STACKTYPE -->
    <overlay-form
      :overlay-title="'Редактировать тип оборудования'"
      :form-type="'edit'"
      :modal-state="editTypeModal"
      @close-modal="editTypeModal = false"
      @submit-button="editStackType()"
    >
      <v-form>
        <v-text-field v-model.trim="input.name" label="Полное название" />
        <v-text-field
          v-model.trim="input.codeName"
          label="Кодовое наименование"
        />
      </v-form>
    </overlay-form>

    <!-- BODY -->
    <v-container fluid>
      <v-card flat :dark="$dark.get()" class="stack-system__container">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>CodeName</th>
                <th>
                  <v-btn
                    icon
                    color="success"
                    @click="
                      addTypeModal = true;
                      clearInput();
                    "
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="renderPage()">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(type, index) in STACK_TYPES" :key="index">
                <td>{{ type.id }}</td>
                <td>{{ type.name }}</td>
                <td>{{ type.codeName }}</td>
                <td>
                  <v-list-item-icon>
                    <v-btn icon @click="openStackType(type)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="deleteModal(type)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>

    <!-- DELETE DIALOG -->
    <delete-dialog
      :show="showDeleteDialog"
      @close-modal="showDeleteDialog = false"
      @delete-modal="deleteStackType()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loader from "../components/Loader";
import OverlayForm from "../components/stackSystem/OverlayForm.vue";
import DeleteDialog from "@/components/DeleteDialog";

export default {
  name: "StackTypes",
  components: {
    loader,
    OverlayForm,
    DeleteDialog,
  },
  data() {
    return {
      loading: true,
      editTypeModal: false,
      addTypeModal: false,
      showDeleteDialog: false,
      selectedStackType: {},
      input: {
        codeName: "",
        name: "",
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
      STACK_TYPES: "StackSystem/STACK_TYPES",
      CITY: "City/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.renderPage();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_ALL_STACK_TYPES: "StackSystem/GET_ALL_STACK_TYPES",
      ADD_NEW_STACK_TYPE: "StackSystem/ADD_NEW_STACK_TYPE",
      EDIT_STACK_TYPE: "StackSystem/EDIT_STACK_TYPE",
      DELETE_STACK_TYPE: "StackSystem/DELETE_STACK_TYPE",
    }),
    async renderPage() {
      this.clearInput();
      if (this.USER.userInfo.departments.length > 0) {
        await this.GET_ALL_STACK_TYPES();
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text:
            "нет доступных филиалов у данного аккаунта в " +
            this.CITY.allCity.filter((e) => e.id == this.$root.city)[0].name,
        });
      }
    },
    clearInput() {
      this.input = {
        codeName: "",
        name: "",
        id: null,
      };
    },
    openStackType(stackType) {
      this.input.name = stackType.name;
      this.input.codeName = stackType.codeName;
      this.input.id = stackType.id;
      this.editTypeModal = true;
    },
    async addNewStackType() {
      this.loading = true;
      const response = await this.ADD_NEW_STACK_TYPE({
        name: this.input.name,
        codeName: this.input.codeName,
      });
      this.addTypeModal = false;
      if (response.type === "error") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      }
      await this.renderPage();
      this.loading = false;
    },
    async editStackType(stackType) {
      this.loading = true;
      const response = await this.EDIT_STACK_TYPE({
        stackTypeId: this.input.id,
        input: {
          name: this.input.name,
          codeName: this.input.codeName,
        },
      });
      this.editTypeModal = false;
      if (response.type === "error") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      }
      await this.renderPage();
      this.loading = false;
    },
    async deleteStackType() {
      this.loading = true;
      const response = await this.DELETE_STACK_TYPE(this.selectedStackType.id);
      this.showDeleteDialog = false;
      if (response.type === "error") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      }
      await this.renderPage();
      this.loading = false;
    },
    deleteModal(stackType) {
      this.showDeleteDialog = true;
      this.selectedStackType = stackType;
    },
  },
};
</script>

<style lang="scss"></style>
